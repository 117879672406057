import React, { useState, useEffect, useCallback } from 'react';
import logo from './okunokentaro.svg';
import twitter from './twitter.svg';
import github from './github.svg';
import speakerdeck from './speakerdeck.svg';
import note from './note.svg';
import soundcloud from './soundcloud.svg';
import zenn from './zenn.svg';
import avatar from './okunokentaro-photo.jpg';
import './App.css';

const display = 'display';
const hidden = 'hidden';

export default function App() {
  const [biographyLinkClassName, setBiographyLinkClassName] = useState(display);
  const [headerClassName, setHeaderLinkClassName] = useState(display);
  const [biographyEnClassName, setBiographyEnClassName] = useState(display);
  const [biographyJaClassName, setBiographyJaClassName] = useState(hidden);

  const enableEn = useCallback(
    () => {
      setBiographyEnClassName(display);
      setBiographyJaClassName(hidden);
    },
    [biographyEnClassName, biographyJaClassName],
  );

  const enableJa = useCallback(
    () => {
      setBiographyJaClassName(display);
      setBiographyEnClassName(hidden);
    },
    [biographyEnClassName, biographyJaClassName],
  );

  useEffect(() => {
    if (window.navigator.language.includes('ja')) {
      setBiographyEnClassName(hidden);
      setBiographyJaClassName(display);
    } else {
      setBiographyEnClassName(display);
      setBiographyJaClassName(hidden);
    }
  }, []);

  useEffect(
    () => {
      window.addEventListener('scroll', () => {
        if (50 < window.pageYOffset) {
          setBiographyLinkClassName(hidden);
        }

        if (200 < window.pageYOffset) {
          setHeaderLinkClassName(hidden);
        } else {
          setHeaderLinkClassName(display);
        }
      });
    },
    [biographyLinkClassName],
  );

  return (
    <div className="App">
      <div className={'App-header ' + headerClassName}>
        <img src={logo} className="App-logo" alt="okunokentaro" />
        <div className="App-icon">
          <a href="https://twitter.com/okunokentaro">
            <img src={twitter} className="App-iconItem" alt="twitter" />
          </a>
          <a href="https://github.com/okunokentaro">
            <img src={github} className="App-iconItem" alt="github" />
          </a>
          <a href="https://zenn.dev/okunokentaro">
            <img src={zenn} className="App-iconItem" alt="zenn" />
          </a>
          <a href="https://speakerdeck.com/okunokentaro">
            <img src={speakerdeck} className="App-iconItem" alt="speakerdeck" />
          </a>
          <a href="https://note.mu/okunokentaro">
            <img src={note} className="App-iconItem" alt="note" />
          </a>
          <a href="https://soundcloud.com/okunokentaro">
            <img src={soundcloud} className="App-iconItem" alt="soundcloud" />
          </a>
        </div>
      </div>
      <div className={'App-biography ja ' + biographyJaClassName}>
        <div className="App-biographyHead">
          <img src={avatar} alt="okunokentaro photo" className="App-biographyPhoto" />
          <div className="App-biographyHeadData">
            <p className="App-biographyName">奥野 賢太郎</p>
            <p className="App-biographyNameEn">OKUNOKENTARO</p>
          </div>
        </div>
        <div className="App-biographyText">
          <p>
            奥野賢太郎はアプリケーション・エンジニア、Web
            Musicインストラクターである。自身の経歴に音楽家とエンジニアの両側面を持ち、その経歴を活かし『Webと音楽の融合』を事業活動とする。
          </p>
          <p>1988年3月1日、京都府京都市に生まれる。</p>
          <p>
            4歳のときスーパーファミコン用ソフト『マリオペイント』にて初めてマウスに触れ、作曲を経験する。6歳のとき、Macintosh
            Centris 650上で動くHyperCardにて初めてプログラミングを経験する。
            2000年からシンセサイザーやピアノでの作曲活動を始め、学生時代は吹奏楽部、軽音楽部にて演奏を続けた。同時期、自身の創作物の掲載のためにWebサイトを開設、HTML,
            CSSを学ぶ。
          </p>
          <p>
            音楽家として志し、2006年、アン・ミュージック・スクール京都校作曲科に入学、ポピュラー音楽・ピアノを亀田邦宏氏、デジタルミュージックを加藤雅春氏に師事。2010年より、更なる技術と表現力の向上のため藤林由里氏に師事。和声法・管弦楽法などを学び、活動の幅を広げた。
          </p>
          <p>
            2009年より音楽業界に身を置き、作曲家として活動を開始。Android用アプリ、プレイステーション・ポータブル用ソフトなどで主題歌・ゲーム音楽を制作。このほか結婚式BGM・舞台音響・効果音制作、ライブ演奏、オーケストラ・アシスタント業務などを行う。
          </p>
          <p>
            2006年よりWebデザインのアルバイトを通じ、複数のコーポレートサイト、ECサイトをデザイン。jQueryやPHP,
            WordPressを習得する。
            また、音楽活動中には業務効率化としてのプログラミングを始め、その中で得られたノウハウをツール化し自身の業務ツールとして運用。その際にObjective-C,
            JavaScript, TypeScriptを学ぶ。
          </p>
          <p>
            2014年より、プログラミングに勝機を見出し音楽活動を縮小。エンジニアとしての活動を拡大し、AngularJSによるアプリケーション開発案件を受託。2015年、Angular日本ユーザー会公認Angularユーザグループng-kyotoを、本籍地京都市にて開設、代表として活動を行う。
          </p>
          <p>
            2015年よりChatWork株式会社（現：Chatwork株式会社）フロントエンドエンジニアとして勤務、2017年より株式会社ピクセルグリッドにてフロントエンドエンジニアとして勤務。Rust,
            Scalaなどを習得し言語の幅を広げる。2018年8月1日よりCrescware（クレスウェア）として独立し、アプリケーション開発受託、設計コンサルティング業務に従事、現在に至る。
          </p>
          <p>
            AMEI認定MIDI検定講師資格、MIDI検定1級資格所持。音楽専門学校School of Music
            Planにて、デジタルミュージック科講師に従事。Web Music Hackathon
            #5にてローランド特別賞を受賞。Angularデベロッパーズガイド（出版：インプレス）を執筆。Angular日本ユーザー会副オーガナイザ。他、企画、講演、連載執筆、番組出演など多数。
          </p>
        </div>
      </div>
      <div className={'App-biography en ' + biographyEnClassName}>
        <div className="App-biographyHead">
          <img src={avatar} alt="okunokentaro photo" className="App-biographyPhoto" />
          <div className="App-biographyHeadData">
            <p className="App-biographyName">OKUNOKENTARO</p>
          </div>
        </div>
        <div className="App-biographyText">
          <p>
            An application engineer and a Web music instructor. He has both sides of a musician and
            an engineer in his career. He makes use of his career as a "integration of web and
            music" as a business.
          </p>
          <p>Born in Mar 1, 1988 in Kyoto Japan.</p>
          <p>
            At the age of 4 he touched the mouse for the first time at Super NES's video game "Mario
            Paint" and experienced composition. At the age of 6, he first experience programming on
            a HyperCard running on Macintosh Centris 650.
          </p>
          <p>
            He began composing music with synthesizers and pianos since 2000 and continued to
            perform at the brass band and light music section during his student days. At the same
            time, open a website for publishing his creations, learn HTML, CSS.
          </p>
          <p>
            He aspired as a musician. In 2006, he enrolled in composition department at AN MUSIC
            SCHOOL Kyoto, studied popular music and digital music. Since 2010, he studies in order
            to improve further technology and expressive power. He learned the law of harmony and
            the orchestration method, etc. and expanded the range of activities.
          </p>
          <p>
            His composer's activities began in 2009 and put himself in the music industry. Produced
            theme songs and game music for Android application, PlayStation Portable software etc.
            In addition to doing wedding BGM, stage sound, sound production, live performance,
            orchestra assistant work, etc.
          </p>
          <p>
            Since 2006, through part-time job of web design, he designed multiple corporate sites
            and EC sites. Learned jQuery, PHP, WordPress. Also, during music activities, he started
            programming as work efficiency, tooling the know-how gained in that tool and operating
            it as his own business tool. At that time, he learned Objective-C, JavaScript,
            TypeScript.
          </p>
          <p>
            In 2014, found a chance of victory for programming and reduced music activity. Expanding
            activities as an engineer and receiving application development projects by AngularJS.
            In 2015, the Angular Japan User Group nominated the local Angular user group "ng-kyoto"
            in Kyoto his hometown, and acted as representative.
          </p>
          <p>
            Worked as front-end engineer at Chatwork from 2015, and worked as a front-end engineer
            at PixelGrid Inc. in 2017. Rust, Scala etc, and expanded the range of languages. Since
            August 1, 2018, he became independent as Crescware, engaged in application development
            consignment, architecture consulting work, and it reaches the present.
          </p>
          <p>
            the MIDI licence lecturer qualification possession certified by AMEI. The MIDI licence
            1st grade possession. Engaged in instructor of digital music at the music school "School
            of Music Plan". Won the Roland Special Award at Web Music Hackathon #5. Writing "Angular
            Developers Guide". Angular Japan User Association Secondary Organizer. Event planning,
            lectures at a conference, writing a series, appearance to a broadcast, and so on.
          </p>
        </div>
      </div>
      <div className="App-footer">
        <div>
          <a className="App-footerToggleLang" onClick={() => enableEn()}>
            English
          </a>{' '}
          |{' '}
          <a className="App-footerToggleLang" onClick={() => enableJa()}>
            日本語 Japanese
          </a>
        </div>
        <p>Since Jul 18, 2001. © OKUNOKENTARO</p>
      </div>
      <div className="App-biographyLink">
        <div className={'App-biographyLinkText ' + biographyLinkClassName}>Biography</div>
      </div>
    </div>
  );
}
